const countryCodes = [
  {
    displayName: 'Afghanistan',
    id: '+93',
    code: 'AF',
  },
  {
    displayName: 'Albania',
    id: '+355',
    code: 'AL',
  },
  {
    displayName: 'Algeria',
    id: '+213',
    code: 'DZ',
  },
  {
    displayName: 'AmericanSamoa',
    id: '+1 684',
    code: 'AS',
  },
  {
    displayName: 'Andorra',
    id: '+376',
    code: 'AD',
  },
  {
    displayName: 'Angola',
    id: '+244',
    code: 'AO',
  },
  {
    displayName: 'Anguilla',
    id: '+1 264',
    code: 'AI',
  },
  {
    displayName: 'Antigua and Barbuda',
    id: '+1268',
    code: 'AG',
  },
  {
    displayName: 'Argentina',
    id: '+54',
    code: 'AR',
  },
  {
    displayName: 'Armenia',
    id: '+374',
    code: 'AM',
  },
  {
    displayName: 'Aruba',
    id: '+297',
    code: 'AW',
  },
  {
    displayName: 'Australia',
    id: '+61',
    code: 'AU',
  },
  {
    displayName: 'Austria',
    id: '+43',
    code: 'AT',
  },
  {
    displayName: 'Azerbaijan',
    id: '+994',
    code: 'AZ',
  },
  {
    displayName: 'Bahamas',
    id: '+1 242',
    code: 'BS',
  },
  {
    displayName: 'Bahrain',
    id: '+973',
    code: 'BH',
  },
  {
    displayName: 'Bangladesh',
    id: '+880',
    code: 'BD',
  },
  {
    displayName: 'Barbados',
    id: '+1 246',
    code: 'BB',
  },
  {
    displayName: 'Belarus',
    id: '+375',
    code: 'BY',
  },
  {
    displayName: 'Belgium',
    id: '+32',
    code: 'BE',
  },
  {
    displayName: 'Belize',
    id: '+501',
    code: 'BZ',
  },
  {
    displayName: 'Benin',
    id: '+229',
    code: 'BJ',
  },
  {
    displayName: 'Bermuda',
    id: '+1 441',
    code: 'BM',
  },
  {
    displayName: 'Bhutan',
    id: '+975',
    code: 'BT',
  },
  {
    displayName: 'Bosnia and Herzegovina',
    id: '+387',
    code: 'BA',
  },
  {
    displayName: 'Botswana',
    id: '+267',
    code: 'BW',
  },
  {
    displayName: 'Brazil',
    id: '+55',
    code: 'BR',
  },
  {
    displayName: 'British Indian Ocean Territory',
    id: '+246',
    code: 'IO',
  },
  {
    displayName: 'Bulgaria',
    id: '+359',
    code: 'BG',
  },
  {
    displayName: 'Burkina Faso',
    id: '+226',
    code: 'BF',
  },
  {
    displayName: 'Burundi',
    id: '+257',
    code: 'BI',
  },
  {
    displayName: 'Cambodia',
    id: '+855',
    code: 'KH',
  },
  {
    displayName: 'Cameroon',
    id: '+237',
    code: 'CM',
  },
  {
    displayName: 'Canada',
    id: '+1',
    code: 'CA',
  },
  {
    displayName: 'Cape Verde',
    id: '+238',
    code: 'CV',
  },
  {
    displayName: 'Cayman Islands',
    id: '+ 345',
    code: 'KY',
  },
  {
    displayName: 'Central African Republic',
    id: '+236',
    code: 'CF',
  },
  {
    displayName: 'Chad',
    id: '+235',
    code: 'TD',
  },
  {
    displayName: 'Chile',
    id: '+56',
    code: 'CL',
  },
  {
    displayName: 'China',
    id: '+86',
    code: 'CN',
  },
  {
    displayName: 'Christmas Island',
    id: '+61',
    code: 'CX',
  },
  {
    displayName: 'Colombia',
    id: '+57',
    code: 'CO',
  },
  {
    displayName: 'Comoros',
    id: '+269',
    code: 'KM',
  },
  {
    displayName: 'Congo',
    id: '+242',
    code: 'CG',
  },
  {
    displayName: 'Cook Islands',
    id: '+682',
    code: 'CK',
  },
  {
    displayName: 'Costa Rica',
    id: '+506',
    code: 'CR',
  },
  {
    displayName: 'Croatia',
    id: '+385',
    code: 'HR',
  },
  {
    displayName: 'Cuba',
    id: '+53',
    code: 'CU',
  },
  {
    displayName: 'Cyprus',
    id: '+537',
    code: 'CY',
  },
  {
    displayName: 'Czech Republic',
    id: '+420',
    code: 'CZ',
  },
  {
    displayName: 'Denmark',
    id: '+45',
    code: 'DK',
  },
  {
    displayName: 'Djibouti',
    id: '+253',
    code: 'DJ',
  },
  {
    displayName: 'Dominica',
    id: '+1 767',
    code: 'DM',
  },
  {
    displayName: 'Dominican Republic',
    id: '+1 849',
    code: 'DO',
  },
  {
    displayName: 'Ecuador',
    id: '+593',
    code: 'EC',
  },
  {
    displayName: 'Egypt',
    id: '+20',
    code: 'EG',
  },
  {
    displayName: 'El Salvador',
    id: '+503',
    code: 'SV',
  },
  {
    displayName: 'Equatorial Guinea',
    id: '+240',
    code: 'GQ',
  },
  {
    displayName: 'Eritrea',
    id: '+291',
    code: 'ER',
  },
  {
    displayName: 'Estonia',
    id: '+372',
    code: 'EE',
  },
  {
    displayName: 'Ethiopia',
    id: '+251',
    code: 'ET',
  },
  {
    displayName: 'Faroe Islands',
    id: '+298',
    code: 'FO',
  },
  {
    displayName: 'Fiji',
    id: '+679',
    code: 'FJ',
  },
  {
    displayName: 'Finland',
    id: '+358',
    code: 'FI',
  },
  {
    displayName: 'France',
    id: '+33',
    code: 'FR',
  },
  {
    displayName: 'French Guiana',
    id: '+594',
    code: 'GF',
  },
  {
    displayName: 'French Polynesia',
    id: '+689',
    code: 'PF',
  },
  {
    displayName: 'Gabon',
    id: '+241',
    code: 'GA',
  },
  {
    displayName: 'Gambia',
    id: '+220',
    code: 'GM',
  },
  {
    displayName: 'Georgia',
    id: '+995',
    code: 'GE',
  },
  {
    displayName: 'Germany',
    id: '+49',
    code: 'DE',
  },
  {
    displayName: 'Ghana',
    id: '+233',
    code: 'GH',
  },
  {
    displayName: 'Gibraltar',
    id: '+350',
    code: 'GI',
  },
  {
    displayName: 'Greece',
    id: '+30',
    code: 'GR',
  },
  {
    displayName: 'Greenland',
    id: '+299',
    code: 'GL',
  },
  {
    displayName: 'Grenada',
    id: '+1 473',
    code: 'GD',
  },
  {
    displayName: 'Guadeloupe',
    id: '+590',
    code: 'GP',
  },
  {
    displayName: 'Guam',
    id: '+1 671',
    code: 'GU',
  },
  {
    displayName: 'Guatemala',
    id: '+502',
    code: 'GT',
  },
  {
    displayName: 'Guinea',
    id: '+224',
    code: 'GN',
  },
  {
    displayName: 'Guinea-Bissau',
    id: '+245',
    code: 'GW',
  },
  {
    displayName: 'Guyana',
    id: '+595',
    code: 'GY',
  },
  {
    displayName: 'Haiti',
    id: '+509',
    code: 'HT',
  },
  {
    displayName: 'Honduras',
    id: '+504',
    code: 'HN',
  },
  {
    displayName: 'Hungary',
    id: '+36',
    code: 'HU',
  },
  {
    displayName: 'Iceland',
    id: '+354',
    code: 'IS',
  },
  {
    displayName: 'India',
    id: '+91',
    code: 'IN',
  },
  {
    displayName: 'Indonesia',
    id: '+62',
    code: 'ID',
  },
  {
    displayName: 'Iraq',
    id: '+964',
    code: 'IQ',
  },
  {
    displayName: 'Ireland',
    id: '+353',
    code: 'IE',
  },
  {
    displayName: 'Israel',
    id: '+972',
    code: 'IL',
  },
  {
    displayName: 'Italy',
    id: '+39',
    code: 'IT',
  },
  {
    displayName: 'Jamaica',
    id: '+1 876',
    code: 'JM',
  },
  {
    displayName: 'Japan',
    id: '+81',
    code: 'JP',
  },
  {
    displayName: 'Jordan',
    id: '+962',
    code: 'JO',
  },
  {
    displayName: 'Kazakhstan',
    id: '+7 7',
    code: 'KZ',
  },
  {
    displayName: 'Kenya',
    id: '+254',
    code: 'KE',
  },
  {
    displayName: 'Kiribati',
    id: '+686',
    code: 'KI',
  },
  {
    displayName: 'Kuwait',
    id: '+965',
    code: 'KW',
  },
  {
    displayName: 'Kyrgyzstan',
    id: '+996',
    code: 'KG',
  },
  {
    displayName: 'Latvia',
    id: '+371',
    code: 'LV',
  },
  {
    displayName: 'Lebanon',
    id: '+961',
    code: 'LB',
  },
  {
    displayName: 'Lesotho',
    id: '+266',
    code: 'LS',
  },
  {
    displayName: 'Liberia',
    id: '+231',
    code: 'LR',
  },
  {
    displayName: 'Liechtenstein',
    id: '+423',
    code: 'LI',
  },
  {
    displayName: 'Lithuania',
    id: '+370',
    code: 'LT',
  },
  {
    displayName: 'Luxembourg',
    id: '+352',
    code: 'LU',
  },
  {
    displayName: 'Madagascar',
    id: '+261',
    code: 'MG',
  },
  {
    displayName: 'Malawi',
    id: '+265',
    code: 'MW',
  },
  {
    displayName: 'Malaysia',
    id: '+60',
    code: 'MY',
  },
  {
    displayName: 'Maldives',
    id: '+960',
    code: 'MV',
  },
  {
    displayName: 'Mali',
    id: '+223',
    code: 'ML',
  },
  {
    displayName: 'Malta',
    id: '+356',
    code: 'MT',
  },
  {
    displayName: 'Marshall Islands',
    id: '+692',
    code: 'MH',
  },
  {
    displayName: 'Martinique',
    id: '+596',
    code: 'MQ',
  },
  {
    displayName: 'Mauritania',
    id: '+222',
    code: 'MR',
  },
  {
    displayName: 'Mauritius',
    id: '+230',
    code: 'MU',
  },
  {
    displayName: 'Mayotte',
    id: '+262',
    code: 'YT',
  },
  {
    displayName: 'Mexico',
    id: '+52',
    code: 'MX',
  },
  {
    displayName: 'Monaco',
    id: '+377',
    code: 'MC',
  },
  {
    displayName: 'Mongolia',
    id: '+976',
    code: 'MN',
  },
  {
    displayName: 'Montenegro',
    id: '+382',
    code: 'ME',
  },
  {
    displayName: 'Montserrat',
    id: '+1664',
    code: 'MS',
  },
  {
    displayName: 'Morocco',
    id: '+212',
    code: 'MA',
  },
  {
    displayName: 'Myanmar',
    id: '+95',
    code: 'MM',
  },
  {
    displayName: 'Namibia',
    id: '+264',
    code: 'NA',
  },
  {
    displayName: 'Nauru',
    id: '+674',
    code: 'NR',
  },
  {
    displayName: 'Nepal',
    id: '+977',
    code: 'NP',
  },
  {
    displayName: 'Netherlands',
    id: '+31',
    code: 'NL',
  },
  {
    displayName: 'Netherlands Antilles',
    id: '+599',
    code: 'AN',
  },
  {
    displayName: 'New Caledonia',
    id: '+687',
    code: 'NC',
  },
  {
    displayName: 'New Zealand',
    id: '+64',
    code: 'NZ',
  },
  {
    displayName: 'Nicaragua',
    id: '+505',
    code: 'NI',
  },
  {
    displayName: 'Niger',
    id: '+227',
    code: 'NE',
  },
  {
    displayName: 'Nigeria',
    id: '+234',
    code: 'NG',
  },
  {
    displayName: 'Niue',
    id: '+683',
    code: 'NU',
  },
  {
    displayName: 'Norfolk Island',
    id: '+672',
    code: 'NF',
  },
  {
    displayName: 'Northern Mariana Islands',
    id: '+1 670',
    code: 'MP',
  },
  {
    displayName: 'Norway',
    id: '+47',
    code: 'NO',
  },
  {
    displayName: 'Oman',
    id: '+968',
    code: 'OM',
  },
  {
    displayName: 'Pakistan',
    id: '+92',
    code: 'PK',
  },
  {
    displayName: 'Palau',
    id: '+680',
    code: 'PW',
  },
  {
    displayName: 'Panama',
    id: '+507',
    code: 'PA',
  },
  {
    displayName: 'Papua New Guinea',
    id: '+675',
    code: 'PG',
  },
  {
    displayName: 'Paraguay',
    id: '+595',
    code: 'PY',
  },
  {
    displayName: 'Peru',
    id: '+51',
    code: 'PE',
  },
  {
    displayName: 'Philippines',
    id: '+63',
    code: 'PH',
  },
  {
    displayName: 'Poland',
    id: '+48',
    code: 'PL',
  },
  {
    displayName: 'Portugal',
    id: '+351',
    code: 'PT',
  },
  {
    displayName: 'Puerto Rico',
    id: '+1 939',
    code: 'PR',
  },
  {
    displayName: 'Qatar',
    id: '+974',
    code: 'QA',
  },
  {
    displayName: 'Romania',
    id: '+40',
    code: 'RO',
  },
  {
    displayName: 'Rwanda',
    id: '+250',
    code: 'RW',
  },
  {
    displayName: 'Samoa',
    id: '+685',
    code: 'WS',
  },
  {
    displayName: 'San Marino',
    id: '+378',
    code: 'SM',
  },
  {
    displayName: 'Saudi Arabia',
    id: '+966',
    code: 'SA',
  },
  {
    displayName: 'Senegal',
    id: '+221',
    code: 'SN',
  },
  {
    displayName: 'Serbia',
    id: '+381',
    code: 'RS',
  },
  {
    displayName: 'Seychelles',
    id: '+248',
    code: 'SC',
  },
  {
    displayName: 'Sierra Leone',
    id: '+232',
    code: 'SL',
  },
  {
    displayName: 'Singapore',
    id: '+65',
    code: 'SG',
  },
  {
    displayName: 'Slovakia',
    id: '+421',
    code: 'SK',
  },
  {
    displayName: 'Slovenia',
    id: '+386',
    code: 'SI',
  },
  {
    displayName: 'Solomon Islands',
    id: '+677',
    code: 'SB',
  },
  {
    displayName: 'South Africa',
    id: '+27',
    code: 'ZA',
  },
  {
    displayName: 'South Georgia and the South Sandwich Islands',
    id: '+500',
    code: 'GS',
  },
  {
    displayName: 'Spain',
    id: '+34',
    code: 'ES',
  },
  {
    displayName: 'Sri Lanka',
    id: '+94',
    code: 'LK',
  },
  {
    displayName: 'Sudan',
    id: '+249',
    code: 'SD',
  },
  {
    displayName: 'Suriname',
    id: '+597',
    code: 'SR',
  },
  {
    displayName: 'Swaziland',
    id: '+268',
    code: 'SZ',
  },
  {
    displayName: 'Sweden',
    id: '+46',
    code: 'SE',
  },
  {
    displayName: 'Switzerland',
    id: '+41',
    code: 'CH',
  },
  {
    displayName: 'Tajikistan',
    id: '+992',
    code: 'TJ',
  },
  {
    displayName: 'Thailand',
    id: '+66',
    code: 'TH',
  },
  {
    displayName: 'Togo',
    id: '+228',
    code: 'TG',
  },
  {
    displayName: 'Tokelau',
    id: '+690',
    code: 'TK',
  },
  {
    displayName: 'Tonga',
    id: '+676',
    code: 'TO',
  },
  {
    displayName: 'Trinidad and Tobago',
    id: '+1 868',
    code: 'TT',
  },
  {
    displayName: 'Tunisia',
    id: '+216',
    code: 'TN',
  },
  {
    displayName: 'Turkey',
    id: '+90',
    code: 'TR',
  },
  {
    displayName: 'Turkmenistan',
    id: '+993',
    code: 'TM',
  },
  {
    displayName: 'Turks and Caicos Islands',
    id: '+1 649',
    code: 'TC',
  },
  {
    displayName: 'Tuvalu',
    id: '+688',
    code: 'TV',
  },
  {
    displayName: 'Uganda',
    id: '+256',
    code: 'UG',
  },
  {
    displayName: 'Ukraine',
    id: '+380',
    code: 'UA',
  },
  {
    displayName: 'United Arab Emirates',
    id: '+971',
    code: 'AE',
  },
  {
    displayName: 'United Kingdom',
    id: '+44',
    code: 'GB',
  },
  {
    displayName: 'United States',
    id: '+1',
    code: 'US',
  },
  {
    displayName: 'Uruguay',
    id: '+598',
    code: 'UY',
  },
  {
    displayName: 'Uzbekistan',
    id: '+998',
    code: 'UZ',
  },
  {
    displayName: 'Vanuatu',
    id: '+678',
    code: 'VU',
  },
  {
    displayName: 'Wallis and Futuna',
    id: '+681',
    code: 'WF',
  },
  {
    displayName: 'Yemen',
    id: '+967',
    code: 'YE',
  },
  {
    displayName: 'Zambia',
    id: '+260',
    code: 'ZM',
  },
  {
    displayName: 'Zimbabwe',
    id: '+263',
    code: 'ZW',
  },
  {
    displayName: 'land Islands',
    id: '',
    code: 'AX',
  },
  {
    displayName: 'Antarctica',
    id: null,
    code: 'AQ',
  },
  {
    displayName: 'Bolivia, Plurinational State of',
    id: '+591',
    code: 'BO',
  },
  {
    displayName: 'Brunei Darussalam',
    id: '+673',
    code: 'BN',
  },
  {
    displayName: 'Cocos (Keeling) Islands',
    id: '+61',
    code: 'CC',
  },
  {
    displayName: 'Congo, The Democratic Republic of the',
    id: '+243',
    code: 'CD',
  },
  {
    displayName: "Cote d'Ivoire",
    id: '+225',
    code: 'CI',
  },
  {
    displayName: 'Falkland Islands (Malvinas)',
    id: '+500',
    code: 'FK',
  },
  {
    displayName: 'Guernsey',
    id: '+44',
    code: 'GG',
  },
  {
    displayName: 'Holy See (Vatican City State)',
    id: '+379',
    code: 'VA',
  },
  {
    displayName: 'Hong Kong',
    id: '+852',
    code: 'HK',
  },
  {
    displayName: 'Iran, Islamic Republic of',
    id: '+98',
    code: 'IR',
  },
  {
    displayName: 'Isle of Man',
    id: '+44',
    code: 'IM',
  },
  {
    displayName: 'Jersey',
    id: '+44',
    code: 'JE',
  },
  {
    displayName: "Korea, Democratic People's Republic of",
    id: '+850',
    code: 'KP',
  },
  {
    displayName: 'Korea, Republic of',
    id: '+82',
    code: 'KR',
  },
  {
    displayName: "Lao People's Democratic Republic",
    id: '+856',
    code: 'LA',
  },
  {
    displayName: 'Libyan Arab Jamahiriya',
    id: '+218',
    code: 'LY',
  },
  {
    displayName: 'Macao',
    id: '+853',
    code: 'MO',
  },
  {
    displayName: 'Macedonia, The Former Yugoslav Republic of',
    id: '+389',
    code: 'MK',
  },
  {
    displayName: 'Micronesia, Federated States of',
    id: '+691',
    code: 'FM',
  },
  {
    displayName: 'Moldova, Republic of',
    id: '+373',
    code: 'MD',
  },
  {
    displayName: 'Mozambique',
    id: '+258',
    code: 'MZ',
  },
  {
    displayName: 'Palestinian Territory, Occupied',
    id: '+970',
    code: 'PS',
  },
  {
    displayName: 'Pitcairn',
    id: '+872',
    code: 'PN',
  },
  {
    displayName: 'Réunion',
    id: '+262',
    code: 'RE',
  },
  {
    displayName: 'Russia',
    id: '+7',
    code: 'RU',
  },
  {
    displayName: 'Saint Barthélemy',
    id: '+590',
    code: 'BL',
  },
  {
    displayName: 'Saint Helena, Ascension and Tristan Da Cunha',
    id: '+290',
    code: 'SH',
  },
  {
    displayName: 'Saint Kitts and Nevis',
    id: '+1 869',
    code: 'KN',
  },
  {
    displayName: 'Saint Lucia',
    id: '+1 758',
    code: 'LC',
  },
  {
    displayName: 'Saint Martin',
    id: '+590',
    code: 'MF',
  },
  {
    displayName: 'Saint Pierre and Miquelon',
    id: '+508',
    code: 'PM',
  },
  {
    displayName: 'Saint Vincent and the Grenadines',
    id: '+1 784',
    code: 'VC',
  },
  {
    displayName: 'Sao Tome and Principe',
    id: '+239',
    code: 'ST',
  },
  {
    displayName: 'Somalia',
    id: '+252',
    code: 'SO',
  },
  {
    displayName: 'Svalbard and Jan Mayen',
    id: '+47',
    code: 'SJ',
  },
  {
    displayName: 'Syrian Arab Republic',
    id: '+963',
    code: 'SY',
  },
  {
    displayName: 'Taiwan, Province of China',
    id: '+886',
    code: 'TW',
  },
  {
    displayName: 'Tanzania, United Republic of',
    id: '+255',
    code: 'TZ',
  },
  {
    displayName: 'Timor-Leste',
    id: '+670',
    code: 'TL',
  },
  {
    displayName: 'Venezuela, Bolivarian Republic of',
    id: '+58',
    code: 'VE',
  },
  {
    displayName: 'Viet Nam',
    id: '+84',
    code: 'VN',
  },
  {
    displayName: 'Virgin Islands, British',
    id: '+1 284',
    code: 'VG',
  },
  {
    displayName: 'Virgin Islands, U.S.',
    id: '+1 340',
    code: 'VI',
  },
]

export default countryCodes
