import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import styled from 'styled-components';
import Navbar from '../../components/Navbar/Navbar';
import Section from '../../components/Section/Section';
import { Helmet } from 'react-helmet';
import Panel from '../../components/Panel/Panel';

interface User {
  displayName?: string;
  id?: string;
  uid?: string;
  role?: string;
}

const Wrapper = styled.div`
  background-color: #f5f8fa;
  height: 100vh;
`;

export const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    const fetchUsers = () => {
      firebase
        .database()
        .ref('users')
        .on('value', (snapshot) => {
          const data = snapshot.val();
          const userList = data ? Object.keys(data).map((key) => ({ id: key, ...data[key] })) : [];
          userList.sort((a, b) => (a.displayName ?? '').localeCompare(b.displayName ?? ''));
          setUsers(userList);
          setIsLoading(false);
        });
    };

    fetchUsers();
  }, []);

  const handleFilterTable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const filteredUsers = users.filter(
    (user) => !filterValue || user.displayName?.toLowerCase().includes(filterValue.toLowerCase())
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <Navbar />

      <Section>
        <Helmet title="Users" />
        <Panel title="Users">
          <div className="bp3-input-group bp3-large mb2">
            <span className="bp3-icon bp3-icon-search" />
            <input
              onChange={handleFilterTable}
              className="bp3-input"
              type="search"
              placeholder="Search for users..."
              dir="auto"
            />
          </div>

          <table className="w-100 bp3-html-table bp3-html-table-interactive bp3-html-table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.displayName ?? ''}</td>
                  <td>{user.id ?? ''}</td>
                  <td>{user.role ?? '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel>
      </Section>
    </Wrapper>
  );
};
