import { createActions } from 'redux-actions'
import firebase from 'firebase/app'
import 'firebase/database'

import { getCurrencyPreference } from '../../helpers/currencyPreferenceHelpers'
import { logProblem } from '../../helpers/logProblem'

export const {
  createBuildRequest,
  createBuildSuccess,
  createBuildFailure,
  fetchBuildRequest,
  fetchBuildSuccess,
  fetchBuildFailure,
  updateBuildRequest,
  updateBuildSuccess,
  updateBuildFailure,
  syncBuildSuccess,
} = createActions(
  'CREATE_BUILD_REQUEST',
  'CREATE_BUILD_SUCCESS',
  'CREATE_BUILD_FAILURE',
  'FETCH_BUILD_REQUEST',
  'FETCH_BUILD_SUCCESS',
  'FETCH_BUILD_FAILURE',
  'UPDATE_BUILD_REQUEST',
  'UPDATE_BUILD_SUCCESS',
  'UPDATE_BUILD_FAILURE',
  'SYNC_BUILD_SUCCESS'
)

/**
 * Loop through all the addons to find the first
 * addon of the specified addon type.
 */
function getFirstAddonOfType(addons, type) {
  for (let i = 0; i < addons.result.length; i++) {
    const entity = addons.entities[addons.result[i]]
    if (!entity._embedded['wp:term']) {
      return undefined
    }
    for (let j = 0; j < entity._embedded['wp:term'].length; j++) {
      for (let k = 0; k < entity._embedded['wp:term'][j].length; k++) {
        if (
          entity._embedded['wp:term'][j][k] &&
          entity._embedded['wp:term'][j][k].slug === type &&
          entity._embedded['wp:term'][j][k].taxonomy === 'addonType'
        ) {
          return entity
        }
      }
    }
  }
  return undefined
}

// Creates a new order in Firebase
export const createBuild = (id, boatSlug, values, options) => {
  const addonLineItems = []

  /**
   * For boats that have the 'construction' addon type defined, we need
   * to automatically select the first addon within the 'construction' type.
   */
  const firstConstruction = getFirstAddonOfType(options.addons, 'construction')
  if (firstConstruction) {
    addonLineItems.push({
      addon: firstConstruction.id,
    })
  }

  let seat = options?.seats?.result[0]
  let top = options?.tops?.result[0]
  let drivetrain = options?.drivetrains?.result[0]
  let firstColorArea = options?.colorareas?.result[0]
  let secondColorArea = options?.colorareas?.result[1]

  let colorEntities = options?.colorareas?.entities
  const turnColorArray = Object.values(colorEntities)

  if (!turnColorArray || !turnColorArray.length) {
    logProblem('No color areas properly defined for this boat type')
    //   console.warn('Color areas are not properly defined for this boat type', key);
    //   );
  }

  let getColors = turnColorArray.map((item) => {
    let firstColor = null
    let secondColor = null
    if (item.id === firstColorArea) {
      firstColor = item.acf?.colors[0]?.color.term_id
    }
    if (item.id === secondColorArea) {
      secondColor = item.acf?.colors[0]?.color.term_id
    }
    return {
      firstColor,
      secondColor,
    }
  })

  let removeNull = getColors.map((item) => {
    if (item.firstColor || item.secondColor !== null) {
      return item
    }
    return undefined
  })

  removeNull = removeNull.filter((item) => {
    return item !== undefined
  })

  let colors = {
    [firstColorArea]: removeNull[0].firstColor,
    [secondColorArea]: removeNull[1].secondColor,
  }

  options?.colorareas?.result.forEach((areaId) => {
    colors = {
      ...colors,
      [areaId]: options?.colorareas?.entities[areaId]?.acf?.colors[0]?.color?.term_id,
    }
  })

  const updates = {
    [`/builds/${id}`]: {
      id,
      boatSlug,
      seat,
      top,
      colors,
      drivetrain,
      addonLineItems,
      step: 'drivetrain',
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      status: 'Request',
      currency: getCurrencyPreference(),
      ...values,
    },
  }
  return {
    types: [createBuildRequest().type, createBuildSuccess().type, createBuildFailure().type],
    promise: firebase.database().ref().update(updates),
  }
}

// Sync build with Firebase for Realtime Goodness™
export const syncBuild = (id) => (dispatch, getState) => {
  return firebase
    .database()
    .ref(`/builds/${id}`)
    .on('value', (snap) => {
      dispatch(syncBuildSuccess(snap.val()))
    })
}

// Fetch one build by id from Firebase
export const fetchBuild = (id) => {
  return {
    types: [fetchBuildRequest().type, fetchBuildSuccess().type, fetchBuildFailure().type],
    promise: firebase.database().ref(`/builds/${id}`).once('value'),
  }
}

// Save a build in Firebase
const saveBuild = (updates) => {
  return {
    types: [updateBuildRequest().type, updateBuildSuccess().type, updateBuildFailure().type],
    promise: firebase.database().ref().update(updates),
  }
}

// Save specific values on a build in Firebase
export const updateBuild =
  (values, type = null, id) =>
  (dispatch, getState) => {
    let updates = {
      [`/builds/${id}/updatedAt`]: Date.now(),
    }
    // eslint-disable-next-line
    Object.keys(values).map((key) => {
      if (type) {
        updates[`/builds/${id}/${type}/${key}`] = values[key]
      } else {
        updates[`/builds/${id}/${key}`] = values[key]
      }
    })
    return dispatch(saveBuild(updates))
  }
