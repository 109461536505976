/**
 * Check if the given string is a valid GUID. This is used to evaluate the
 * Shapediver parameter IDs given from the WordPress backend, so the backend
 * admins can use either GUID or Parameter name when specifying parameters.
 *
 * @param str The string to test
 * @returns Whether the string is a valid GUID
 */
export function isGuid(str: string): boolean {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(str);
}
