import firebase from 'firebase/app';
import { useState } from 'react';
import { setOptionsByBoatmodel } from '../../store/Selection/actions';
import { helloCall } from '../../api/firebase';
// import 'firebase/functions'

export function DevelopmentPage() {
  const [output, setOutput] = useState('');

  async function onClickHello() {
    const response = await helloCall();
    setOutput(JSON.stringify(response.data, null, 2));
  }

  return (
    <div>
      <div>
        <h1>Development page</h1>
      </div>
      <div>
        <button onClick={onClickHello}>Hello</button>
      </div>
      Output:
      <pre>{output}</pre>
    </div>
  );
}
