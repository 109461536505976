import firebase from 'firebase/app';

export function helloCall() {
  return firebase.app().functions('europe-west1').httpsCallable('helloCall')();
}

export function transferBuildToWordpress(orderId: string) {
  return firebase.app().functions('europe-west1').httpsCallable('transferBuildToWordpress')({
    orderId,
  });
}

export interface PrepareDepositCheckoutInput {
  email: string;
  title: string;
  currency: string;
  buildId: string;
}

export function prepareDepositCheckout(data: PrepareDepositCheckoutInput) {
  return firebase.app().functions('europe-west1').httpsCallable('prepareDepositCheckout')(data);
}

interface SendRequestInfoRequestInput {
  firstName: string;
  lastName: string;
  email: string;
}

export function sendRequestInfoRequest(data: SendRequestInfoRequestInput) {
  return firebase.app().functions('europe-west1').httpsCallable('sendRequestInfoRequest')(data);
}
