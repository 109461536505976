import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
// Components
import NoMatch from '../../components/NoMatch/NoMatch'
import RouteWhenAuthorized from '../../components/RouteWhenAuthorized/RouteWhenAuthorized'
// import Dashboard from '../Dashboard/Dashboard'
import Orders from '../Orders/Orders'
import { Users } from '../Users/Users'
import Edit from '../Edit/Edit'
import View from '../View/View'
import Login from '../Login/Login'
import Signup from '../Signup/Signup'
import User from '../User/User'
import Builder from '../Builder/Builder'
import Elements from '../Elements'
import PaymentSuccess from '../PaymentSuccess'
import OrderConfirmation from '../../components/OrderConfirmation/OrderConfirmation'
import { DevelopmentPage } from '../DevelopmentPage/DevelopmentPage'

const theme = {
  primaryColor: '#FBB71D',
  primaryBlack: '#1E1E1F',
  primaryLightGrey: '#DBE1E3',
  primaryLighterGrey: '#E9E9E9',
  primaryGreen: '#43B245',
  primaryYellow: '#FBB71D',
  primaryPurple: '#9678f4',
  primaryNewGrey: '#e8efee',
  primaryOrange: '#fb5515',
}

export class App extends Component {
  render() {
    const { user } = this.props
    const isAuthenticated = user && user.user

    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <main>
            <Helmet titleTemplate="%s - Goldfish Boat" defaultTitle="Builder - Goldfish Boat" />
            <Switch>
              <RouteWhenAuthorized
                exact
                strict
                path="/"
                isAuthenticated={isAuthenticated}
                component={Orders}
              />
              <Route exact path="/orders/" render={() => <Redirect to="/" />} />
              <RouteWhenAuthorized
                exact
                strict
                path="/orders/"
                isAuthenticated={isAuthenticated}
                component={Orders}
              />
              <RouteWhenAuthorized
                exact
                strict
                path="/users"
                isAuthenticated={isAuthenticated}
                component={Users}
              />
              <RouteWhenAuthorized
                exact
                strict
                path={`/orders/:id/edit/`}
                isAuthenticated={isAuthenticated}
                component={Edit}
              />
              <Route exact strict path={`/orders/:id/view/`} component={View} />
              <Route
                exact
                strict
                path={`/orders/:id/view/order-confirmation`}
                component={OrderConfirmation}
              />

              <Route exact strict path={`/elements`} component={Elements} />
              <RouteWhenAuthorized
                exact
                strict
                path={`/user/`}
                isAuthenticated={isAuthenticated}
                component={User}
              />
              <Route exact strict path="/login/" component={Login} />
              <Route exact strict path="/signup/" component={Signup} />
              <Route strict path="/boats/:model/build/:id?" component={Builder} />
              <Route strict path="/payment-success" component={PaymentSuccess} />

              <Route exact strict path="/dev" component={DevelopmentPage} />

              <Route component={NoMatch} />
            </Switch>
          </main>
        </ThemeProvider>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, {})(App)
