/* global Stripe */

import '@reach/dialog/styles.css'
import firebase from 'firebase/app'
import 'firebase/functions'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import shortid from 'shortid'
import styled from 'styled-components'
import Loading from '../../components/Loading/Loading'
import SelectionBar from '../../components/SelectionBar/SelectionBar'
import ThankYou from '../../components/ThankYou/ThankYou'
import TopMenu from '../../components/TopMenu'
import { config } from '../../config'
import areEquipmentRequirementsMet from '../../helpers/areEquipmentRequirementsMet'
import { createBuild, fetchBuild, syncBuild, updateBuild } from '../../store/Builds/actions'
import { getBuildById } from '../../store/Builds/selectors'
import { getCommonOptions } from '../../store/Common/actions'
import { getUserCurrency } from '../../store/Common/selectors'
import { fetchOptions } from '../../store/Options/actions'
import { getOptions, getOptionsIsFetching } from '../../store/Options/selectors'
import { createOrder } from '../../store/Orders/actions'
import Drivetrain from '../Drivetrain/Drivetrain'
import QuoteRequest from '../QuoteRequest/QuoteRequest'
import Style from '../Style/Style'
import Summary from '../Summary/Summary'
import Cart from './Cart'
import { CurrencySelector } from './CurrencySelector/CurrencySelector'
import { hideDiscontinuedOptions } from './hideDiscontinuedOptions'
import { prepareDepositCheckout, sendRequestInfoRequest } from '../../api/firebase'

const Wrapper = styled.section`
  /* background: red; */
  /* background-size: 80px auto; */
  min-height: ${window.innerHeight}px;
`

class Builder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      touchedSelections: [],
      isNewBuild: false,
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.myRef = React.createRef()
  }

  componentDidMount() {
    window.Intercom('boot', {
      app_id: 'ifnnnh7h',
      hide_default_launcher: true,
      custom_launcher_selector: '#chat',
    })
    this.props
      .fetchOptions(this.props.match.params.model)
      .then((response) => {
        if (this.props.match.params.id && this.props.location.search !== '?locked') {
          // It's normal open build
          this.props.syncBuild(this.props.match.params.id)
        } else if (this.props.location.search === '?locked') {
          // It has the '?locked' query param -> fetch the original requested build
          this.props.fetchBuild(this.props.match.params.id).then((response) => {
            // Generate a new id and copy over the original build data
            const id = shortid.generate()
            let values = response.result.val()
            // Replace the id key with the new one
            values.id = id
            // Create the build and start the normal redirect and sync process

            const optionsWithoutDiscontinued = hideDiscontinuedOptions(this?.props?.options)

            const relativeUrl = this.props.location.pathname.substring(this.props.match.url.length)

            this.props
              .createBuild(id, null, values, optionsWithoutDiscontinued)
              .then((response) =>
                this.props.history.replace(
                  // Redirect to summary page
                  // `/boats/${this.props.match.params.model}/build/${id}/summary`
                  // Redirect to same relative URL on the newly created build
                  `/boats/${this.props.match.params.model}/build/${id}${relativeUrl}`
                )
              )
              .then((response) => this.props.syncBuild(this.props.match.params.id))
          })
        } else {
          // It's not locked and not exsiting
          const boatObject =
            this?.props?.options?.boats?.entities[this?.props?.options?.boats?.result[0]]
          const boatmodel = boatObject?.boatmodel[0]
          const boat = boatObject?.id
          const id = shortid.generate()

          const optionsWithoutDiscontinued = hideDiscontinuedOptions(this?.props?.options)

          this.props
            // generating and id
            .createBuild(
              id,
              this?.props?.match?.params?.model,
              { boatmodel, boat },
              optionsWithoutDiscontinued
            )
            .then((response) => this.props.history.replace(`${this.props.match.url}/${id}/style`))
            .then((response) => this.props.syncBuild(this.props.match.params.id))
            .then(() => {
              this.setState((prevState) => {
                return {
                  ...prevState,
                  isNewBuild: true,
                }
              })
            })
        }
      })
      .then((response) => this.props.getCommonOptions())
  }

  handleSelect(e) {
    // e.preventDefault()
    let fieldName, fieldValue, subName
    const buildId = this.props.match.params.id
    if (e.currentTarget.dataset !== undefined) {
      fieldName = e.currentTarget.name || e.currentTarget.dataset.name
      fieldValue = e.currentTarget.value || e.currentTarget.dataset.value
      subName = e.currentTarget.dataset.type // To set a child containing name and value
    } else {
      fieldName = e.currentTarget.name || e.currentTarget.getAttribute('data-name')
      fieldValue = e.currentTarget.value || e.currentTarget.getAttribute('data-value')
      subName = e.currentTarget.getAttribute('data-type') // To set a child containing name and value
    }
    if (subName === 'equipmentLineItems' || subName === 'addonLineItems') {
      const {
        build: { equipmentLineItems, addonLineItems },
      } = this.props

      if (subName === 'equipmentLineItems') {
        // Create new array if one doesn't exist
        let newEquipmentArray = equipmentLineItems || []
        // Check if the id you want to push is in the array allready and in that case get the index
        const obj = newEquipmentArray.find((x) => x.equipment === fieldValue)

        if (obj) {
          const index = newEquipmentArray.indexOf(obj)
          // If it allready exists we want to remove it
          newEquipmentArray.splice(index, 1)
        } else {
          // If it does not exist we want to add it
          newEquipmentArray.push({ equipment: fieldValue })
        }
        this.props.updateBuild({ [subName]: newEquipmentArray }, null, buildId)

        const equipmentType = e.currentTarget.equipmenttype || e.currentTarget.dataset.equipmenttype
        this.setState((prevState) => ({
          touchedSelections: [...prevState.touchedSelections, equipmentType],
        }))
      }

      if (subName === 'addonLineItems') {
        /**
         * We need to figure out whether this addon type only allows a single
         * choice or not. If it does, we need to remove the other options.
         */

        // First, get the entity object for this specific addon
        const addonEntityId = parseInt(fieldValue, 10)
        const addonEntity = this.props.options.addons.entities[addonEntityId]

        // Second, check if the addon type is `only_allow_single_choice`
        const addonTypeEntity = addonEntity._embedded['wp:term'][0].find(
          (i) => i.taxonomy === 'addonType'
        )
        const onlyAllowSingleChoiceForThisAddon = addonTypeEntity.acf.only_allow_single_choice

        // Third, we need to find the other addons for this type
        const otherAddonsInTheSameType = Object.values(this.props.options.addons.entities)
          .filter(
            (addon) => addon.addonType.includes(addonTypeEntity.id) && addon.id !== addonEntityId
          )
          .map((x) => x)

        // This is the _new_ list of addons, after this change
        let newAddonArray = addonLineItems || []

        // Check if the id you want to push is in the array already and in that case get the index
        const existingAddon = newAddonArray.find((x) => x.addon === fieldValue)

        if (existingAddon) {
          // We're toggling the addon _off_, just remove the addon and be done with it:
          const existingAddonIndex = newAddonArray.indexOf(existingAddon)
          // If it allready exists we want to remove it
          newAddonArray.splice(existingAddonIndex, 1)
        } else {
          // If it does not exist we want to add it
          newAddonArray.push({ addon: fieldValue })

          // If the addon type is `only_allow_single_choice`, then remove the other options
          if (onlyAllowSingleChoiceForThisAddon) {
            otherAddonsInTheSameType.forEach((otherAddon) => {
              const ix = newAddonArray.findIndex((x) => parseInt(x.addon, 10) === otherAddon.id)
              if (ix >= 0) {
                newAddonArray.splice(ix, 1)
              }
            })
          }
        }

        this.props.updateBuild({ [subName]: newAddonArray }, null, buildId)

        const addonType = e.currentTarget.equipmenttype || e.currentTarget.dataset.equipmenttype
        // console.log({ addonType })
        // console.log(e.currentTarget)
        this.setState((prevState) => ({
          touchedSelections: [...prevState.touchedSelections, addonType],
        }))
      }
    } else {
      this.props.updateBuild({ [fieldName]: fieldValue }, subName, buildId)
      this.setState((prevState) => ({
        touchedSelections: [...prevState.touchedSelections, fieldName],
      }))
    }

    // if (subName === 'addonLineItems') {
    //   const {
    //     build: { addonLineItems },
    //   } = this.props
    //   // Create new array if one doesn't exist
    //   let newAddonArray = addonLineItems || []
    //   // Check if the id you want to push is in the array allready and in that case get the index
    //   const obj = newAddonArray.find((x) => x.addon === fieldValue)
    //   if (obj) {
    //     const index = newAddonArray.indexOf(obj)
    //     // If it allready exists we want to remove it
    //     newAddonArray.splice(index, 1)
    //   } else {
    //     // If it does not exist we want to add it
    //     newAddonArray.push({ addon: fieldValue })
    //   }

    //   this.props.updateBuild({ [subName]: newAddonArray }, null, buildId)

    //   const addonType = e.currentTarget.addontype || e.currentTarget.dataset.addontype
    //   this.setState((prevState) => ({
    //     touchedSelections: [...prevState.touchedSelections, addonType],
    //   }))
    // } else {
    //   this.props.updateBuild({ [fieldName]: fieldValue }, subName, buildId)
    //   this.setState((prevState) => ({
    //     touchedSelections: [...prevState.touchedSelections, fieldName],
    //   }))
    // }

    if (fieldName === 'drivetrain') {
      // Handle equipments with requirements
      const {
        build: { equipmentLineItems },
        options: {
          equipments: { entities: allEquipments },
        },
      } = this.props
      if (equipmentLineItems) {
        // console.log('equipmentLineItems', equipmentLineItems)
        const equipments = equipmentLineItems.filter((item) => {
          const id = item.equipment
          const equipment = allEquipments[id]
          const requirementsMet = areEquipmentRequirementsMet(equipment, this.props.build)
          if (!requirementsMet) {
            return false
          } else {
            return true
          }
        })
        if (equipments.length !== equipmentLineItems.length) {
          this.props.updateBuild({ equipmentLineItems: equipments }, null, buildId)
        }
      }
    }
  }

  handleSubmit = async (values) => {
    const buildId = shortid.generate()

    const { build, options } = this.props

    build.id = buildId
    build.sendEmailConfirmation = false

    const data = {
      ...build,
      ...values,
    }

    const currency = build.currency.toLowerCase()

    const boatTitle = options.boats.entities[build.boat].title.rendered
    const drivetrainTitle = options.drivetrains.entities[build.drivetrain].acf.display_name

    if (!values.requestType || values.requestType !== 'pay-deposit') {
      data.sendEmailConfirmation = false
      data.status = 'Request'
      this.props
        .createOrder(buildId, data)
        .then(async (res) => {
          await sendRequestInfoRequest({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
          })
        })
        .then((response) => this.props.history.push(`${this.props.match.url}/thank-you/`))
    } else {
      data.status = 'Pending Payment'

      try {
        const response = await prepareDepositCheckout({
          email: values.email,
          title: `${boatTitle} - ${drivetrainTitle}`,
          currency: currency,
          buildId,
        })
        const CHECKOUT_SESSION_ID = response.data.id
        const PAYMENT_INTENT = response.data.payment_intent
        data.stripeCheckoutSessionId = CHECKOUT_SESSION_ID
        data.stripePaymentIntentId = PAYMENT_INTENT
        await this.props.createOrder(buildId, data)
        const stripe = Stripe(config.stripe.publishableKey)
        // const stripe = Stripe(
        //   'pk_test_51LLL74LclJkD1IpKY6KxaHkVRAMJQzAI4uFFXal2zJq1jz3WSkqKS8veFza6dMxMXxMzL3pPuRkATLEFFIqu8wzX00n3qL2xW1'
        // )
        const { error } = await stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: CHECKOUT_SESSION_ID,
        })
        console.error(error)
      } catch (error) {
        console.error(error)
      }
    }
  }

  render() {
    const {
      match,
      isFetching,
      build,
      userCurrency,
      options: { drivetrains, tops, seats, colorareas, equipments, boats, addons },
    } = this.props
    // console.log('yoo', this.state.touchedSelections)
    if (isFetching) {
      return (
        <Wrapper className="h-100">
          {/* <StepHeader>
            <StepBar match={match} />
          </StepHeader> */}
          <div className="flex db vh-100 w-100 items-center content-center">
            <Loading />
          </div>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        {/* {process.env.NODE_ENV !== 'production' && ( */}
        <TopMenu>
          <div className="w-100 flex h-100 items-center gradient-grey">
            <div className="p-1 ph3 br h-100 flex-m flex-l items-center b--light-gray mr3 dn ">
              {build && boats.entities[build.boat].title.rendered}
            </div>
            {build && (
              <div className="flex-auto flex flex-column tr mr3">
                <div>
                  <CurrencySelector
                    drivetrains={drivetrains}
                    tops={tops}
                    seats={seats}
                    colorareas={colorareas}
                    equipments={equipments}
                    addons={addons}
                    build={build}
                    userCurrency={userCurrency}
                    isNewBuild={this.state.isNewBuild}
                    handleSelect={this.handleSelect}
                  ></CurrencySelector>
                </div>
              </div>
            )}
            <div className="h-100 w3 flex items-center bl ph3 ml3 b--light-gray">
              <Cart
                estimatedDeliveryTime={this.props.estimatedDeliveryTime}
                match={match}
                build={build}
                handleSelect={this.handleSelect}
              ></Cart>
            </div>
          </div>
        </TopMenu>

        {/* )} */}
        <Helmet title={`Build your own ${build && boats.entities[build.boat].title.rendered}`} />
        <Route
          path={`${match.url}/style`}
          exact
          render={() => <Redirect to={`${match.url}/style/drivetrains`} />}
        />
        <Route
          path={`${match.path}/style/:openView`}
          render={(props) => (
            <SelectionBar
              openView={props.match.params.openView}
              handleSelect={this.handleSelect}
              userCurrency={userCurrency}
              options={this.props.options}
              build={build}
              match={match}
            />
          )}
        />
        {/* <Route
          path={`${match.url}/options`}
          render={() => (
            <SelectionBar
              handleSelect={this.handleSelect}
              userCurrency={userCurrency}
              options={this.props.options}
              build={build}
              match={match}
            />
          )}
        /> */}
        <Route
          path={`${match.path}/drivetrains`}
          render={() => (
            <Drivetrain
              build={build}
              options={drivetrains}
              boats={boats}
              handleSelect={this.handleSelect}
            />
          )}
        />
        <Route
          path={`${match.path}/style/:openView?`}
          render={(props) => (
            <Style
              openView={props.match.params.openView}
              match={match}
              build={build}
              options={this.props.options}
              handleSelect={this.handleSelect}
              touchedSelections={this.state.touchedSelections}
            />
          )}
        />
        {/* <Route
          path={`${match.url}/options`}
          render={() => (
            <Options build={build} options={equipments} handleSelect={this.handleSelect} />
          )}
        /> */}
        <Route
          path={`${match.url}/summary/`}
          render={(props) => (
            <Summary
              estimatedDeliveryTime={this.props.estimatedDeliveryTime}
              match={match}
              build={build}
              options={this.props.options}
              handleSelect={this.handleSelect}
            />
          )}
        />
        <Route
          path={`${match.url}/quote/:requestType?`}
          render={(props) => (
            <QuoteRequest
              build={build}
              requestType={props.match.params.requestType}
              onSubmit={this.handleSubmit}
              match={props.match}
            />
          )}
        />
        <Route path={`${match.url}/thank-you`} render={() => <ThankYou />} />
        <button
          aria-label="Chat with us"
          id="chat"
          className="bw0 br-100 background-green geo-regular f5 fixed top-0 right-0 mr5  white pointer flex items-center tc justify-center"
          style={{ width: '30px', height: '30px', marginTop: '76px' }}
        >
          <svg
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 28 32"
            width="16"
            style={{ marginLeft: '-2px' }}
          >
            <path
              fill="#fff"
              d="M28,32 C28,32 23.2863266,30.1450667 19.4727818,28.6592 L3.43749107,28.6592 C1.53921989,28.6592 0,27.0272 0,25.0144 L0,3.6448 C0,1.632 1.53921989,0 3.43749107,0 L24.5615088,0 C26.45978,0 27.9989999,1.632 27.9989999,3.6448 L27.9989999,22.0490667 L28,22.0490667 L28,32 Z M23.8614088,20.0181333 C23.5309223,19.6105242 22.9540812,19.5633836 22.5692242,19.9125333 C22.5392199,19.9392 19.5537934,22.5941333 13.9989999,22.5941333 C8.51321617,22.5941333 5.48178311,19.9584 5.4277754,19.9104 C5.04295119,19.5629428 4.46760991,19.6105095 4.13759108,20.0170667 C3.97913051,20.2124916 3.9004494,20.4673395 3.91904357,20.7249415 C3.93763774,20.9825435 4.05196575,21.2215447 4.23660523,21.3888 C4.37862552,21.5168 7.77411059,24.5386667 13.9989999,24.5386667 C20.2248893,24.5386667 23.6203743,21.5168 23.7623946,21.3888 C23.9467342,21.2215726 24.0608642,20.9827905 24.0794539,20.7254507 C24.0980436,20.4681109 24.0195551,20.2135019 23.8614088,20.0181333 Z"
            ></path>
          </svg>
        </button>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, props) => ({
  options: getOptions(state),
  isFetching: getOptionsIsFetching(state),
  build: getBuildById(state, props.match.params.id),
  userCurrency: getUserCurrency(state),
  estimatedDeliveryTime: state.common.estimatedDeliveryTime,
})

export default connect(mapStateToProps, {
  fetchOptions,
  createBuild,
  fetchBuild,
  syncBuild,
  updateBuild,
  createOrder,
  getCommonOptions,
})(Builder)
